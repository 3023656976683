import './i18n/dates/dayjs-config'

import { CssBaseline, LinearProgress, createTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Theme, ThemeProvider } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { dentalbookApi } from '@newsoftds/api-portal-paciente'
import * as Sentry from '@sentry/react'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Amplify, Auth } from 'aws-amplify'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { StrictMode, Suspense, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { RecoilEnv, RecoilRoot, useRecoilValue } from 'recoil'
import './i18n/i18n'
import ErrorPage from './pages/utils/ErrorPage'
import { Router } from './routes'
import { authStateSelector } from './store/AuthStateAtom'
import { ClinicStateAtom } from './store/ClinicStateAtom'
import { themeModeAtom } from './store/ThemeStateAtom'
import { ScrollBar } from './theme/ScrollBar'
import { ErrorBoundary } from './utils/errors/ErrorBoundary'

Sentry.init({
  dsn: 'https://b17d44e6779b48b1985dc6a14de1d124@o315858.ingest.sentry.io/4505076028145664',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: ['https://api.portalpaciente.pt'],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header']
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: import.meta.env.MODE === 'production'
})

posthog.init(import.meta.env.NS_PATIENT_PORTAL_POSTHOG_KEY, {
  api_host: import.meta.env.NS_PATIENT_PORTAL_POSTHOG_HOST,
  autocapture: false,
  capture_pageview: false,
  capture_pageleave: false
  // capture_console_log: true,
  // person_profiles: 'identified_only'
})

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false
Amplify.configure({
  Auth: {
    region: import.meta.env.NS_AWS_REGION,
    userPoolId: import.meta.env.NS_USER_POOL_ID_PATIENT,
    userPoolWebClientId: import.meta.env.NS_USER_POOL_WEB_CLIENT_ID_PATIENT,
    storage: window.localStorage
  }
})

async function getIdToken() {
  try {
    const session = await Auth.currentSession()
    const user = await Auth.currentAuthenticatedUser()
    Sentry.setUser({ email: user.attributes.email })
    return session.getAccessToken().getJwtToken()
  } catch (error) {
    console.error('Error getting ID token:', error)
    return null
  }
}

dentalbookApi.interceptors.request.use(
  async config => {
    const idToken = await getIdToken()
    if (idToken) {
      config.headers.Authorization = `Bearer ${idToken}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      Sentry.captureException(error, {
        extra: {
          queryKey: query.queryKey,
          queryHash: query.queryHash,
          state: query.state
        }
      })
    }
  })
})

export const Index = () => {
  const themeValues = useRecoilValue(themeModeAtom)
  const authState = useRecoilValue(authStateSelector)
  const clinicState = useRecoilValue(ClinicStateAtom)
  const [errorPageState] = useState(false)

  const theme: Theme = createTheme({
    palette: {
      mode: themeValues.palette?.mode,
      background: {
        default: themeValues.palette?.background?.default,
        paper: themeValues.palette?.background?.paper
      },
      primary: {
        main: clinicState.clinicPrimaryColor || '#008080'
      },
      secondary: {
        main: clinicState.clinicSecondaryColor || '#33ab9f'
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            ...ScrollBar(
              themeValues.palette?.mode === 'light'
                ? {
                    track: grey[200],
                    thumb: grey[400],
                    active: grey[400]
                  }
                : undefined
            ),
            scrollbarWidth: 'thin'
          }
        }
      }
    }
  })

  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt'>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Suspense fallback={<LinearProgress />}>
            <BrowserRouter>
              <PostHogProvider client={posthog}>
                {!errorPageState ? <ErrorBoundary>{(!authState.isLoading || !clinicState.isLoading) && <Router />}</ErrorBoundary> : <ErrorPage />}
                {/* <ReactQueryDevtools initialIsOpen={true} /> */}
              </PostHogProvider>
            </BrowserRouter>
          </Suspense>
        </ThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  )
}

const root = createRoot(document.getElementById('root')!)

root.render(
  <StrictMode>
    <RecoilRoot>
      <Suspense fallback={<div />}>
        <Index />
      </Suspense>
    </RecoilRoot>
  </StrictMode>
)
