import { QueryFunction, useQuery, UseQueryResult } from '@tanstack/react-query'
import { dentalbookApi } from '../../endpoints'

interface Params {
  clinicNif: string
  clinicId: string
  costCenterId: string
  patientNif: string
  patientEmail: string
  patientPhone: string
}

interface CheckPatient {
  patientNif: string
  patientName: string
  patientEmail: string
  patientPhone: string
}

interface Response {
  status: number
  error: string
  message: string
  result: CheckPatient[]
}

type QueryKey = ['checkPatientData', string, string, string, string, string, string]

export const useCheckPatientQuery = (variables: Params): UseQueryResult<Response> => {
  const { clinicNif, clinicId, costCenterId, patientNif, patientEmail, patientPhone } = variables

  const queryKey: QueryKey = ['checkPatientData', clinicNif, clinicId, costCenterId, patientNif, patientEmail, patientPhone]

  const queryFn: QueryFunction<Response> = async () => {
    const { data } = await dentalbookApi.get<Response>(
      `/checkpatient?clinicNif=${clinicNif}&clinicId=${clinicId}&costCenterId=${costCenterId}&patientEmail=${encodeURIComponent(
        patientEmail
      )}&patientPhone=${encodeURIComponent(patientPhone)}&patientNif=${patientNif}`
    )
    return data
  }

  return useQuery<Response>({
    queryKey,
    queryFn,
    enabled: false
  })
}
