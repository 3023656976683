import { useMutation } from '@tanstack/react-query'
import { paymentsApi } from '../../endpoints'

export interface ICheckoutSibsParams {
  sibsClientId?: string
  sibsToken?: string
  episodeId: number
  patientId: number
  terminalId: number
  orderValue: number
  initialPaymentDay?: string
  finalPaymentDay?: string
  paymentType: string
  paymentMethod: string
  entityReference: string
  uniqueIdReference?: string
  patientName: string
  patientEmail: string
  patientStreetAddress1: string
  patientStreetAddress2: string
  patientCity: string
  patientZipCode: string
  patientCountry: string
  patientMobilePhone: string
  configs: string
}

export interface ICheckoutSibsResponse {
  data: {
    amount: {
      value: number
      currency: string
    }
    merchant: {
      terminalId: string
      merchantTransactionId: string
    }
    transactionID: string
    transactionSignature: string
    formContext: string
    expiry: string
    tokenList: unknown[]
    paymentMethodList: string[]
    execution: {
      startTime: string
      endTime: string
    }
    returnStatus: {
      statusCode: string
      statusMsg: string
      statusDescription: string
    }
  }
}

const defaultValues = {
  sibsClientId: '99fe8524-838e-4996-8502-9fc1e73aa188',
  sibsToken:
    '6YOl+HlBN6CGfwqlYz7adh1OalSkmFrEiStE21W+uwpaKjKxE8iB4DNpkzNyckmPmc4An3RZP/Nn8HNrUB33qBQOUnI0uhX8HL5TDE3Vs3bQ2Ky7Oqt00DJDX/EjoN5ExqutLsMuXPk8yfyGm801rl4smiHVCBKIeaKxWJOfmlKLxXWebQWRmW//0s0H30+IctR9YPc+C9DXnQC0YmJeborncprS22G+PQQpAq2srMV20J9TQuZfVBrt0MEsSwBXWVlPxBgafpZnat4+3E0SlDRjOkOk0bbS5MwV8GuGAXaopUeW6aZK4qfIEr8QaANZb5+uRC7KndfISNlUplLihtBWj0YFDj/QADACO9luX3+mTWmNxs83O4BbFD+AbsMk7+ZiyDCJhTnmMdI6TKLUAzWPj/QvPNDxMLPP9tAQNWzN05YtHl74Ysl9x1tSzpftBpf1yhiE4dFrDSfi7c/yqD1t+smuv9ie/Sss6/61cg5fcJOiJaZ6xOBR4HvxMjxBLLhIBVCCAbux4GJLoZrL0U2IaYjUHJksBdGqF1WIYLq+G/snD4tV4pBP40lG3uKNKT8KTYsqjydYKGNqWctco2hsU6T/va/dNJMI9lBvm08iY4h5CbU8UtYkR8u8PerWUAjU1/uoeFwdf+x7U8jecpQ1sBbjdMmJTE1yE2OP4Gk=',
  initialPaymentDay: new Date().toISOString(),
  finalPaymentDay: '2024-06-11T10:26:50.572Z',
  uniqueIdReference: Math.floor(Math.random() * 1000000000).toString()
}

export const useCheckoutSibs = () => {
  return useMutation({
    mutationFn: (checkoutSibsParams: Omit<ICheckoutSibsParams, 'sibsClientId' | 'sibsToken' | 'initialPaymentDay'>) => {
      const data = {
        ...defaultValues,
        ...checkoutSibsParams
      }
      return paymentsApi.post('/checkout-sibs', data, { headers: { isWidget: true } })
    }
  })
}
