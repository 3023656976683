import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Collapse, List, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { navBarState } from '../../store'
import { NavBarAdminItems } from './NavBarAdminItems'

const AdminMenu = ({ id, name, path, icon, subMenu }: { id: number; name: string; path: string; icon: any; subMenu: any }) => {
  const { pathname } = useLocation()
  const [subMenuState, setSubMenuState] = useState(true)
  const currentPath = pathname.split('/')[3]
  const [, setOpen] = useRecoilState(navBarState)
  const theme = useTheme()
  const mediaQuery = useMediaQuery(theme.breakpoints.down('lg'))

  const handleSubMenuState = () => {
    setSubMenuState(!subMenuState)
  }

  const handleMenuCLickedClose = () => {
    if (mediaQuery) {
      setOpen(false)
    }
  }

  return (
    <div key={id + 'menu'}>
      <Link onClick={handleMenuCLickedClose} to={path === 'admin' ? '#' : path} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement="right" title={name}>
          <ListItemButton selected={currentPath === `${path}`} onClick={handleSubMenuState}>
            <ListItemIcon sx={{ marginLeft: 1 }}>{icon}</ListItemIcon>
            <ListItemText sx={{ marginLeft: 3 }} primary={name} />
            {subMenu.length >= 1 ? subMenuState ? <ExpandLess /> : <ExpandMore /> : null}
          </ListItemButton>
        </Tooltip>
      </Link>
      {subMenu.length >= 1
        ? subMenu.map((subMenu: any, ind: any) => (
            <Collapse key={ind + 'sub'} in={subMenuState} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link onClick={handleMenuCLickedClose} to={subMenu.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Tooltip placement="right" title={subMenu.name}>
                    <ListItemButton sx={{ pl: 5 }} selected={currentPath === `${subMenu.path}`}>
                      <ListItemIcon>{subMenu.icon}</ListItemIcon>
                      <ListItemText primary={subMenu.name} />
                    </ListItemButton>
                  </Tooltip>
                </Link>
              </List>
            </Collapse>
          ))
        : null}
    </div>
  )
}

export const NavBarAdminMenus = () => {
  return (
    <>
      {NavBarAdminItems.map(menu => {
        return <AdminMenu key={menu.id} id={menu.id} name={menu.name} path={menu.path} icon={menu.icon} subMenu={menu.subMenu} />
      })}
    </>
  )
}
