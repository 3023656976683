import { dentalbookApi } from '@newsoftds/api-portal-paciente'
import CryptoJS from 'crypto-js'
import { AtomEffect } from 'recoil'

export const fetchClinicDataAndSettings = async () => {
  const pathName = window.location.pathname
  const pathParts = pathName.split('/')
  const clinicKey = pathParts[1] || ''
  const apiUrl = dentalbookApi.getUri()
  try {
    const getClinicData = await fetch(`${apiUrl}/getclinic?key=${clinicKey}`)
    const clinicResponse = await getClinicData.json()
    if (clinicResponse.status === 0) {
      const clinicData = clinicResponse?.result[0]
      const getClinicSettings = await fetch(
        `${apiUrl}/getsettings?clinicNif=${clinicData.clinicNif}&clinicId=${clinicData.clinicId}&costCenterId=${clinicData.costCenterId}`
      )
      const clinicSettingsResponse = await getClinicSettings.json()
      const clinicSettingsData = clinicSettingsResponse?.result[0]

      return {
        clinicData,
        clinicSettingsData,
        clinicKey,
        error: null
      }
    } else if (clinicResponse.error === 'KeyIsInvalid') {
      return {
        error: 'KeyIsInvalid'
      }
    }
  } catch {
    return {
      error: 'KeyIsInvalid'
    }
  }
}

const encryptionKey = import.meta.env.NS_PATIENT_PORTAL_ENCRYPTION_KEY
const encryptData = (data: unknown): string => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString()
}
export const decryptData = (cipherText: string): unknown => {
  const bytes = CryptoJS.AES.decrypt(cipherText, encryptionKey)
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8)
  return JSON.parse(decryptedData)
}

export const localStorageClinicStateEffect =
  <T>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      try {
        const decryptedData = decryptData(savedValue)
        setSelf(decryptedData as T)
        // setSelf(JSON.parse(savedValue))
      } catch (error) {
        console.error('Error decrypting data', error)
      }
    }

    onSet((newValue, _, isReset) => {
      if (isReset) {
        localStorage.removeItem(key)
      } else {
        async function fetchData() {
          const pathName = window.location.pathname
          const pathParts = pathName.split('/')
          const clinicKey = pathParts[1] || ''
          const isLoggedIn = pathParts[2] === 'inicio'
          const apiUrl = dentalbookApi.getUri()
          const getClinicData = await fetch(`${apiUrl}/getclinic?key=${clinicKey}`)
          const clinicResponse = await getClinicData.json()

          if (clinicResponse.error === 'KeyIsInvalid') {
            localStorage.removeItem(key)
          } else {
            // Only save to cache after login
            if (isLoggedIn) {
              // Encrypt and save the new value
              const encryptedData = encryptData(newValue)
              localStorage.setItem(key, encryptedData)
            }
            // localStorage.setItem(key, JSON.stringify(newValue))
          }
        }
        fetchData()
      }
    })
  }
