// @mui
import { Box, Button, Container, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Illustration404 } from '../../assets/svg/Illustration404'

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

export default function ErrorPage() {
  return (
    <Box>
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h4" paragraph>
            Desculpe, algo correu mal!
          </Typography>
          <Box sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 }, paddingBottom: 50 }}>
            <Illustration404 />
          </Box>
          {/* <Button to="/" size="large" variant="contained" component={RouterLink}> */}
          <Button onClick={() => window.location.reload()} size="large" variant="contained">
            Voltar
          </Button>
        </ContentStyle>
      </Container>
    </Box>
  )
}
