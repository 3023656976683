import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Collapse, List, Tooltip } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useGetSettingsQuery } from '@newsoftds/api-portal-paciente'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { navBarState } from '../../store'
import { ClinicStateAtom } from '../../store/ClinicStateAtom'
import { main } from './NavBarItems'

const Menu = ({ id, name, path, icon, subMenu }: { id: number; name: string; path: string; icon: any; subMenu: any }) => {
  const { pathname } = useLocation()
  const [subMenuState, setSubMenuState] = useState(true)
  const currentPath = pathname.split('/')[2]
  const [, setOpen] = useRecoilState(navBarState)
  const theme = useTheme()
  const mediaQuery = useMediaQuery(theme.breakpoints.down('lg'))

  const handleSubMenuState = () => {
    setSubMenuState(!subMenuState)
  }

  const handleMenuCLickedClose = () => {
    if (mediaQuery) {
      setOpen(false)
    }
  }

  return (
    <div key={id + 'menu'}>
      <Link onClick={handleMenuCLickedClose} replace to={path} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Tooltip placement='right' title={name}>
          <ListItemButton selected={currentPath === `${path}`} onClick={handleSubMenuState}>
            <ListItemIcon sx={{ marginLeft: 1 }}>{icon}</ListItemIcon>
            <ListItemText sx={{ marginLeft: 3 }} primary={name} />
            {subMenu.length >= 1 ? subMenuState ? <ExpandLess /> : <ExpandMore /> : null}
          </ListItemButton>
        </Tooltip>
      </Link>
      {subMenu.length >= 1
        ? subMenu.map((subMenu: any, ind: any) => (
            <Collapse key={ind + 'sub'} in={subMenuState} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <Link onClick={handleMenuCLickedClose} replace to={path + '/' + subMenu.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Tooltip placement='right' title={subMenu.name}>
                    <ListItemButton sx={{ pl: 5 }} selected={currentPath === `/${path}/${subMenu.path}`}>
                      <ListItemIcon>{subMenu.icon}</ListItemIcon>
                      <ListItemText primary={subMenu.name} />
                    </ListItemButton>
                  </Tooltip>
                </Link>
              </List>
            </Collapse>
          ))
        : null}
    </div>
  )
}

export const NavBarMenus = () => {
  const clinicState = useRecoilValue(ClinicStateAtom)
  const { data, isLoading } = useGetSettingsQuery({
    clinicNif: clinicState.clinicNif,
    clinicId: clinicState.clinicId,
    costCenterId: clinicState.costCenterId
  })

  // Define a function to render menus to avoid repetition
  const renderMenus = menus => {
    return menus.map(menu => <Menu key={menu.id} id={menu.id} name={menu.name} path={menu.path} icon={menu.icon} subMenu={menu.subMenu} />)
  }

  if (isLoading) {
    // Initially, show all menus while the component is loading
    // Use React Fragment for Consistency: Utilize React Fragment (<> and </>) to return the list of menus. This avoids unnecessary wrapper elements in the DOM.
    return <>{renderMenus(main)}</>
  }

  // Filter out the specialities menu if it should not be shown
  const menusToShow = data?.result[0]?.clinicSpecialitiesMenu === true ? main : main.filter(menu => menu.id !== 3)

  // Conditionally render menus based on the fetched data
  return <>{renderMenus(menusToShow)}</>
}
