import { useMutation } from '@tanstack/react-query'
import { dentalbookApi } from '../../endpoints'

export interface ICreateTemporaryPatientParams {
  clinicNif: string
  clinicId: string
  costCenterId: string
  patientEmail: string
  patientPhone: string
  patientName: string
  patientNif: string
  patientLocation: string
  patientAddress: string
  patientZipCode: string
}

export const useCreateTemporaryPatient = () => {
  return useMutation({
    mutationFn: (temporaryPatient: ICreateTemporaryPatientParams) => dentalbookApi.post('/createtemporarypatient', temporaryPatient)
  })
}
