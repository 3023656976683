import { QueryFunction, useQuery } from '@tanstack/react-query'
import { dentalbookApi } from '../../endpoints'

interface Params {
  clinicNif: string
  clinicId: string
  costCenterId: string
}

export interface ICostCenter {
  clinicNif: string
  clinicId: string
  costCenterId: string
  clinicName: string
  clinicPrimaryColor: string
  clinicSecondaryColor: string
  clinicStreetName: string
  clinicZipCode: string
  clinicPhoneNumber: string
  clinicDescription: string
  clinicHourMorningOpen: string
  clinicHourMorningClose: string
  clinicHourAfternoonOpen: string
  clinicHourAfternoonClose: string
  clinicEmail: string
}

interface Response {
  status: number
  error: string
  message: string
  result: ICostCenter[]
}

type QueryKey = ['costCenterData', string, string, string]

export const useGetCostCenterQuery = (variables: Params) => {
  const { clinicNif, clinicId, costCenterId } = variables

  const queryKey: QueryKey = ['costCenterData', clinicNif, clinicId, costCenterId]

  const queryFn: QueryFunction<Response> = async () => {
    const { data } = await dentalbookApi.get<Response>(`/getcostcenter?clinicNif=${clinicNif}&clinicId=${clinicId}&costCenterId=${costCenterId}`)
    return data
  }
  const isEnabled = Boolean(clinicNif && clinicId && costCenterId);

  return useQuery({ queryKey, queryFn, enabled: isEnabled })
}
