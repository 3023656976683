import { atom } from 'recoil'

export const navBarState = atom({
  key: 'navBarState',
  default: true
})

export const nsStepperState = atom({
  key: 'nsStepperState',
  default: false
})
