import { useMutation } from '@tanstack/react-query'
import { dentalbookApi } from '../../endpoints'

export interface IEmailParams {
  clinicNif: string
  clinicId: string
  costCenterId: string
  mailTo: string | undefined
  mailTitle: string
  mailBody: string
}

export const useSendEmail = () => {
  return useMutation({
    mutationFn: (email: IEmailParams) => dentalbookApi.post('/sendMail', email)
  })
}
