import dayjs from 'dayjs'
import 'dayjs/locale/pl'
import 'dayjs/locale/pt'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isoWeek from 'dayjs/plugin/isoWeek'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekday from 'dayjs/plugin/weekday'

dayjs.extend(localeData)
dayjs.extend(customParseFormat)
dayjs.extend(isoWeek)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(localizedFormat)
dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault('Europe/Lisbon')
dayjs.Ls.pt.weekStart = 0
dayjs.Ls.pl.weekStart = 0
dayjs.Ls.en.weekStart = 0
dayjs.locale('pt')
dayjs.updateLocale('pt', {
  weekStart: 1, // 0 for Sunday, 1 for Monday, etc.
  weekdaysMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  // weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
})

// declare module 'dayjs' {
//   interface Dayjs {
//     toDateHour(formatStr?: string): Date
//   }
// }

// // Define the custom toDateHour plugin
// const toDateHourPlugin = (option: any, dayjsClass: any) => {
//   dayjsClass.prototype.toDateHour = function (formatStr?: string) {
//     const defaultFormat = 'YYYY-MM-DDTHH:mm:ss[Z]'
//     const formattedString = this.format(formatStr || defaultFormat)
//     return new Date(formattedString)
//   }
// }

// dayjs.extend(toDateHourPlugin)

export default dayjs
