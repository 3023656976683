export interface IFindClinic {
  clinicNif: string
  clinicId: string
  costCenterId: string
}

export function findClinicByNif(clinics: IFindClinic[], nif: string): IFindClinic | undefined {
  if (clinics.length === 0) {
    return undefined
  }
  const clinicObject = clinics.find(clinic => Number(clinic.clinicNif) === Number(nif))

  if (!clinicObject) {
    return undefined
  }
  return clinicObject
}
