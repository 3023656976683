import { lazy } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { MainLayout } from '../layouts/MainLayout'
import { AuthenticationPage } from '../pages/authentication/AuthenticationPage'
import { CheckPaymentPage } from '../pages/patient/CheckPaymentPage'
import { AuthenticationNoClinicPage } from '../pages/authentication/AuthenticationNoClinicPage'


const HomePage = lazy(() => import('../pages/patient/HomePage').then(module => ({ default: module.HomePage })))
const MedicsPage = lazy(() => import('../pages/patient/MedicsPage').then(module => ({ default: module.MedicsPage })))
const SpecialitiesPage = lazy(() => import('../pages/patient/SpecialitiesPage').then(module => ({ default: module.SpecialitiesPage })))
const PatientProfile = lazy(() => import('../containers/patientProfile/PatientProfile').then(module => ({ default: module.PatientProfile })))
const RequireAuth = lazy(() => import('./RequireAuth').then(module => ({ default: module.RequireAuth })))
const CheckEmailPage = lazy(() => import('../pages/utils/CheckEmailPage').then(module => ({ default: module.CheckEmailPage })))
const KioskRegisterPage = lazy(() => import('../pages/authentication/KioskRegisterPage').then(module => ({ default: module.KioskRegisterPage })))
const AdminRoutes = lazy(() => import('./AdminRoutes').then(module => ({ default: module.AdminRoutes })))

export const Router = () => {
  const { pathname } = useLocation()
  const clinicKey = pathname.split('/')[1] // It gets the clinicKey from the url, ex: baseURL/clinicKey/login

  return (
    <Routes>
      <Route path="/" element={<AuthenticationNoClinicPage noButton />} />
      <Route path=":clinicKey/login" element={<AuthenticationPage />} />
      <Route path=":clinicKey" element={<RequireAuth />}>
        <Route element={<MainLayout admin={false} />}>
          <Route index path="inicio" element={<HomePage />} />
          <Route path="corpo-clinico" element={<MedicsPage />} />
          <Route path="especialidades" element={<SpecialitiesPage />} />
          <Route path="utilizador" element={<PatientProfile />} />
          <Route path="*" element={<Navigate to={`/${clinicKey}/inicio`} />} />
        </Route>
        <Route path="inicio/pagamento" element={<CheckPaymentPage />} />
        <Route path="admin" element={<MainLayout admin={true} />}>
          <Route path="*" element={<AdminRoutes clinicKey={clinicKey} />} />
        </Route>
      </Route>
      <Route path={`/${clinicKey}`} element={<Navigate to={`/${clinicKey}/inicio`} />} />
      <Route path={`/${clinicKey}/admin`} element={<Navigate to={`/${clinicKey}/admin/clinic-config`} />} />
      <Route path=":clinicKey/checkemail/:sub" element={<CheckEmailPage />} />
      <Route path=":clinicKey/kioskregisteruser" element={<KioskRegisterPage />} />
    </Routes>
  )
}
