import { QueryFunction, useQuery } from '@tanstack/react-query';
import { dentalbookApi } from '../../endpoints';

interface Params {
  clinicNif: string;
  clinicId: string;
  costCenterId: string;
}

export interface ISettings {
  clinicNif: string;
  clinicId: string;
  costCenterId: string;
  clinicLogoImage: string;
  clinicPrimaryColor: string;
  clinicSecondaryColor: string;
  clinicTheme: 'light' | 'dark';
  clinicLanguage: string;
  clinicDirectorName: string;
  clinicWorkingDays: string;
  clinicPrimaryEmail: string;
  clinicRequestEmail: string;
  clinicPrivacyPolicyUrl: string;
  clinicSpecialitiesMenu: boolean;
  maxAppointmentsOnline: number;
  maxAppointmentsPerDay: number;
  numberDaysToRequestAppointment: number;
  numberDaysToCancelAppointment: number;
}

interface Response {
  status: number;
  error: string;
  message: string;
  result: ISettings[];
}

type QueryKey = ['settingsData', string, string, string];

export const useGetSettingsQuery = (variables: Params) => {
  const { clinicNif, clinicId, costCenterId } = variables;

  const queryKey: QueryKey = ['settingsData', clinicNif, clinicId, costCenterId];

  const queryFn: QueryFunction<Response> = async () => {
    const { data } = await dentalbookApi.get<Response>(`/getsettings?clinicNif=${clinicNif}&clinicId=${clinicId}&costCenterId=${costCenterId}`);
    return data;
  };

  const isEnabled = Boolean(clinicNif && clinicId && costCenterId);

  return useQuery({ queryKey, queryFn, refetchOnWindowFocus: false, enabled: isEnabled });
};
