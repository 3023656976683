export const newsoftdsNewPatientErrors = (error: string, data: any) => {
  switch (error) {
    case 'PatientPhoneDoesNotMatch':
      return { phoneNumber: `O telemóvel associado ao email acaba em ${data?.result[0].patientPhone}` }
    case 'PatientPhoneDoesNotExist':
      return { phoneNumber: 'Já tem ficha na clínica, mas não tem nenhum telefone associado. Por favor contacte a sua Clínica.' }
    case 'PatientEmailDoesNotMatch':
      return { name: 'Os seus dados não estão ainda associados a esta clínica, por favor complete-os para o primeiro registo.' }
    case 'PatientEmailEmpty':
      return { email: 'Já tem ficha na clínica, mas não tem nenhum email associado. Por favor contacte a sua Clínica.' }
    case 'PatientNIFDoesNotMatch':
      return {
        patientNif: `O NIF associado ao email acaba em ${data?.result[0].patientNif}`
      }
    // case 'PatientNifMissing':
    //   return {
    //     name: 'Os seus dados não estão ainda associados a esta clínica, por favor complete-os para o primeiro registo.',
    //     patientNif: 'O NIF não está associado a esta clínica, preencha os dados para o primeiro registo.'
    //   }
    case 'PatientNIF/EmailDoesNotMatch':
      return {
        name: 'Os seus dados não estão ainda associados a esta clínica, por favor complete-os para o primeiro registo.',
        patientNif: 'O NIF não está associado a esta clínica, preencha os dados para o primeiro registo.'
      }
    default:
      return { email: 'Algo correu mal, contacte a sua Clínica' }
  }
}
