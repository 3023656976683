import { icons } from '../../theme/icons'
export const main = [
  {
    id: 1,
    name: 'Início',
    path: 'inicio',
    icon: icons.home.icon,
    subMenu: []
  },
  {
    id: 2,
    name: 'Corpo Clínico',
    path: 'corpo-clinico',
    icon: icons.medics.icon,
    subMenu: []
  },
  {
    id: 3,
    name: 'Especialidades',
    path: 'especialidades',
    icon: icons.specialty.icon,
    subMenu: []
  }
]
