import { Box, Typography } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import CoolImage from '../assets/clinic_undraw.svg'
import { authStateSelector } from '../store/AuthStateAtom'
import { NavBar } from './navBar/NavBar'
import { NavBarAdmin } from './navBarAdmin/NavBarAdmin'
import { TopBar } from './topBar/TopBar'

export const MainLayout = ({ admin }: { admin: boolean }) => {
  const authState = useRecoilValue(authStateSelector)

  // console.log('authState', authState)

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <TopBar patientName={authState.patientName} />
        {!admin ? <NavBar /> : <NavBarAdmin />}
        <Box
          component='main'
          width={'auto'}
          sx={
            admin
              ? {
                  flexGrow: 1,
                  p: 3,
                  pt: 10,
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: `url('${CoolImage}')`, // Assuming CoolImage is an imported image
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    opacity: 0.05, // Adjust the opacity value to your preference
                    zIndex: -1
                  }
                }
              : {
                  flexGrow: 1,
                  p: 3,
                  pt: 10
                }
          }
        >
          <Outlet />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', marginLeft: '15vw', overflow: 'hidden', justifyContent: 'center', alignItems: 'flex-end' }}>
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          Desenvolvido por{' '}
          <Box
            component='a'
            href='https://www.imaginasoft.pt'
            target='_blank'
            rel='noreferrer'
            sx={{
              color: 'primary.main',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            Imaginasoft
          </Box>
        </Typography>
      </Box>
    </>
  )
}
