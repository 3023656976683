import { ChangeCircle, Email, LocalPhone, LocationOn, Schedule } from '@mui/icons-material'
import BadgeIcon from '@mui/icons-material/Badge'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useGetCostCenterQuery, useGetSettingsQuery } from '@newsoftds/api-portal-paciente'

import {
  Avatar,
  Badge,
  Box,
  Card,
  Collapse,
  IconButton,
  IconButtonProps,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { ClinicStateAtom } from '../../store/ClinicStateAtom'
import { truncateText } from '../../utils/truncateText'

const CardStyle = styled(Card)(({ theme }) => ({
  background: theme.palette.secondary.main,
  margin: theme.spacing(1),
  overflow: 'hidden',
  position: 'relative'
}))

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

export const ClinicInfoCard = ({ openFn }: any) => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState(false)
  const clinicState = useRecoilValue(ClinicStateAtom)

  const { data: dataCostCenter, isLoading: isLoadingCostCenter } = useGetCostCenterQuery({
    clinicNif: clinicState.clinicNif,
    clinicId: clinicState.clinicId,
    costCenterId: clinicState.costCenterId
  })

  const { data: dataSettings } = useGetSettingsQuery({
    clinicNif: clinicState.clinicNif,
    clinicId: clinicState.clinicId,
    costCenterId: clinicState.costCenterId
  })

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  function handleClickPhone(phoneNumber) {
    if (!phoneNumber) return
    window.open(`tel:${phoneNumber}`)
  }

  const handleClickEmail = email => {
    if (!email) return
    window.open(`mailto:${email}`, '_blank')
  }

  function handleClickAddress(address) {
    if (!address) return

    // You can replace this with your preferred mapping service.
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`
    window.open(mapsUrl, '_blank')
  }

  if (isLoadingCostCenter) {
    return <LinearProgress />
  }

  return (
    <CardStyle>
      <Box sx={{ display: 'flex', margin: 2 }}>
        {/* TODO: Check if there are more then 1 clinic to show change badge and add tooltip */}
        <Tooltip title='Trocar de Clínica'>
          <Badge
            overlap='circular'
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={<ChangeCircle onClick={openFn} sx={{ cursor: 'pointer', fontSize: 22, color: theme.palette.primary.dark }} />}
          >
            <Avatar onClick={openFn} src={clinicState.clinicLogoImage || ''} sx={{ bgcolor: 'white', cursor: 'pointer' }} aria-label='recipe'>
              {dataCostCenter?.result[0].clinicDescription?.charAt(0)}
            </Avatar>
          </Badge>
        </Tooltip>
        <Typography variant='h6' sx={{ color: theme.palette.getContrastText(theme.palette.secondary.main), marginTop: 0.5, marginLeft: 2 }}>
          {truncateText(dataCostCenter?.result[0].clinicDescription, 15)}
        </Typography>
        <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label='show more'>
          <ExpandMoreIcon sx={{ color: 'white' }} />
        </ExpandMore>
      </Box>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <Card sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, maxHeight: 579 }}>
          <List
            sx={{
              bgcolor: 'background.paper'
            }}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <BadgeIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary='Director(a)' secondary={dataSettings?.result[0].clinicDirectorName || ''} />
            </ListItem>
            <ListItem onClick={() => handleClickPhone(dataCostCenter?.result[0].clinicPhoneNumber)} style={{ cursor: 'pointer' }}>
              <ListItemAvatar>
                <Avatar>
                  <LocalPhone />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary='Telefone' secondary={dataCostCenter?.result[0].clinicPhoneNumber} />
            </ListItem>
            <ListItem onClick={() => handleClickEmail(dataSettings?.result[0].clinicPrimaryEmail)} style={{ cursor: 'pointer' }}>
              <ListItemAvatar>
                <Avatar>
                  <Email />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary='Email' secondary={dataSettings?.result[0].clinicPrimaryEmail} />
            </ListItem>
            <ListItem onClick={() => handleClickAddress(dataCostCenter?.result[0].clinicStreetName)} style={{ cursor: 'pointer' }}>
              <ListItemAvatar>
                <Avatar>
                  <LocationOn />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary='Morada'
                secondary={dataCostCenter?.result[0].clinicStreetName}
                sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Schedule />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary='Horário' secondary={dataSettings?.result[0].clinicWorkingDays} />
            </ListItem>
          </List>
        </Card>
      </Collapse>
    </CardStyle>
  )
}
