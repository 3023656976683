import { useTheme } from '@mui/system'
import { useCheckPatientQuery, useCreateTemporaryPatient } from '@newsoftds/api-portal-paciente'
import { Authentication } from '@newsoftds/authentication'
import { Amplify, Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import packageJson from '../../../package.json'
import { AuthSnackbar } from '../../components/snackbars/AuthSnackbar'
import { authStateSelector, fetchUser } from '../../store/AuthStateAtom'
import { awsAuth } from '../../store/AwsAuth'
import { ClinicStateAtom } from '../../store/ClinicStateAtom'
import { awsLoginErrors, awsNewPasswordErrors, awsRegisterErrors } from '../../utils/errors/awsErrors'
import { newsoftdsNewPatientErrors } from '../../utils/errors/newsoftdsErrors'
import { AuthenticationNoClinicPage } from './AuthenticationNoClinicPage'

export const AuthenticationPage = () => {
  const { clinicKey } = useParams()
  const clinicState = useRecoilValue(ClinicStateAtom)
  const [, setAuthGlobalState] = useRecoilState(authStateSelector)
  const [loginLoading, setLoginLoading] = useState(false)
  const [awsState, setAwsState] = useRecoilState(awsAuth)

  const [loginState, setLoginState] = useState({
    email: '',
    password: '',
    errors: {
      email: '',
      password: ''
    }
  })
  const [registerState, setRegisterState] = useState({
    patientEmail: '',
    password: '',
    patientPhone: '',
    patientNif: '',
    phoneCode: '',
    name: '',
    city: '',
    errors: {
      email: '',
      password: '',
      phoneNumber: '',
      patientNif: '',
      phoneCode: '',
      name: '',
      city: ''
    }
  })
  const [newPasswordState, setNewPasswordState] = useState({
    email: '',
    code: '',
    newPassword: '',
    insertCode: false,
    errors: {
      email: '',
      code: '',
      newPassword: ''
    }
  })
  const theme = useTheme()
  const navigate = useNavigate()
  const [tab, setTab] = useState(0)
  const [insertCode, setInsertCode] = useState(false)
  const [recover, setRecover] = useState(false)
  const [smsSentNotification, setSmsSentNotification] = useState(false)
  const [insertCodeNotification, setInsertCodeNotification] = useState(false)
  const [emailRecoverNotification, setEmailRecoverNotification] = useState(false)
  const [searchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries(searchParams)
  const { refetch } = useCheckPatientQuery({
    clinicNif: clinicState.clinicNif,
    clinicId: clinicState.clinicId,
    costCenterId: clinicState.costCenterId,
    patientEmail: registerState.patientEmail,
    patientPhone: registerState.patientPhone,
    patientNif: registerState.patientNif
  })
  const newTemporaryPatient = useCreateTemporaryPatient()
  // const { t } = useTranslationKeys()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (Object.keys(searchParamsObject).length !== 0) {
      setTab(1)
      setRegisterState({
        ...registerState,
        errors: { ...registerState.errors, password: 'Defina a sua password.' }
      })
      if (searchParamsObject.patientEmail) {
        setRegisterState(prevState => ({ ...prevState, patientEmail: searchParamsObject.patientEmail }))
      }
      if (searchParamsObject.patientPhone) {
        setRegisterState(prevState => ({ ...prevState, patientPhone: '+' + searchParamsObject.patientPhone }))
      }
      if (searchParamsObject.patientNif) {
        setRegisterState(prevState => ({ ...prevState, patientNif: searchParamsObject.patientNif }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  Amplify.configure({
    clientMetadata: {
      app: 'www.portalpaciente.pt',
      clinicKey,
      clinicNif: clinicState.clinicNif,
      clinicId: clinicState.clinicId,
      costCenterId: clinicState.costCenterId,
      clinicName: clinicState.clinicName
    }
  })

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const handleRecoverPassword = () => {
    setRecover(!recover)
    setNewPasswordState({
      ...newPasswordState,
      insertCode: false,
      errors: { email: '', code: '', newPassword: '' }
    })
  }

  const handleLogin = async () => {
    setLoginLoading(true)
    // TODO: checkPatient or CheckAWS -> create custom field clinicNif
    try {
      const userAws = await Auth.signIn(loginState.email, loginState.password)
      await fetchUser(userAws, result => {
        userAws && setAwsState({ ...awsState, isLoggedIn: true })
        setAuthGlobalState(result)
        setLoginLoading(false)
        navigate(`/${clinicKey}/inicio`)
      })
    } catch (error) {
      setLoginState(prevState => ({
        ...prevState,
        errors: { ...prevState.errors, ...awsLoginErrors(error.code, error.message) }
      }))
      setLoginLoading(false)
    }
  }
  const handleSendSMS = async () => {
    // setLoginLoading(true)
    Auth.resendSignUp(loginState.email)
      .then(user => {
        console.log('insertCode', user)
        setInsertCode(true)
        setTab(1)

        setRegisterState(prevState => {
          return {
            ...prevState,
            patientEmail: loginState.email,
            errors: {
              email: '',
              password: '',
              phoneNumber: '',
              patientNif: '',
              phoneCode: '',
              name: '',
              city: ''
            }
          }
        })
      })
      .catch(error => {
        console.log('handleResendCode - error', error)
        setRegisterState({
          ...registerState,
          errors: { ...registerState.errors, ...awsRegisterErrors(error.code, error.message) }
        })
      })
    // setLoginLoading(false)
  }

  const handleInsertCode = () => {
    Auth.confirmSignUp(registerState.patientEmail, registerState.phoneCode)
      .then(() => {
        setLoginState({
          ...loginState,
          errors: {
            email: '',
            password: ''
          }
        })
        setTab(0)
        setInsertCodeNotification(true)
        setInsertCode(false)
      })
      .catch(error => {
        console.log('handleInsertCode - error', error)
        setRegisterState({
          ...registerState,
          errors: { ...registerState.errors, ...awsRegisterErrors(error.code, error.message) }
        })
      })
  }

  const handleResendCode = () => {
    Auth.resendSignUp(registerState.patientEmail)
      .then(user => {
        console.log('insertCode', user)
      })
      .catch(error => {
        console.log('handleResendCode - error', error)
        setRegisterState({
          ...registerState,
          errors: { ...registerState.errors, ...awsRegisterErrors(error.code, error.message) }
        })
      })
  }

  const onRegister = async () => {
    const checkEmailQuery = await refetch()

    if (!checkEmailQuery.isLoading) {
      if (checkEmailQuery.data?.status === 1) {
        setRegisterState({
          ...registerState,
          errors: { ...registerState.errors, ...newsoftdsNewPatientErrors(checkEmailQuery.data.error, checkEmailQuery.data) }
        })
      } else if (checkEmailQuery.data?.message === 'UserExists') {
        try {
          await Auth.signUp({
            username: registerState.patientEmail,
            password: registerState.password,
            attributes: {
              phone_number: registerState.patientPhone, // optional - E.164 number convention
              'custom:fiscal_number': registerState.patientNif,
              'custom:permissions': 'patient',
              'custom:clinics': JSON.stringify([{ clinicNif: clinicState.clinicNif, clinicId: clinicState.clinicId, costCenterId: '0' }])
            },
            autoSignIn: {
              enabled: false
            }
          })
          setRegisterState({
            ...registerState,
            errors: {
              email: '',
              password: '',
              phoneNumber: '',
              patientNif: '',
              phoneCode: '',
              name: '',
              city: ''
            }
          })
          setInsertCode(true)
          setSmsSentNotification(true)
        } catch (error) {
          console.log('onRegister', error)
          setRegisterState({
            ...registerState,
            errors: { ...registerState.errors, ...awsRegisterErrors(error.code, error.message) }
          })
        }
      }
    }
  }

  const onRegisterNewPatient = async () => {
    try {
      const { userSub } = await Auth.signUp({
        username: registerState.patientEmail,
        password: registerState.password,
        attributes: {
          phone_number: registerState.patientPhone, // optional - E.164 number convention
          'custom:fiscal_number': registerState.patientNif,
          'custom:permissions': 'patient',
          'custom:clinics': JSON.stringify([{ clinicNif: clinicState.clinicNif, clinicId: clinicState.clinicId, costCenterId: '0' }])
        }
      })
      // Problema de mandar o código de paciente temporário no sms, se temos de criar na aws primeiro.
      if (userSub !== '') {
        newTemporaryPatient.mutate({
          // @ts-ignore
          clinicNif: clinicState.clinicNif,
          clinicId: clinicState.clinicId,
          costCenterId: clinicState.costCenterId,
          patientEmail: registerState.patientEmail,
          patientPhone: registerState.patientPhone,
          // patientPhone: registerState.patientPhone.slice(4),
          patientNif: registerState.patientNif,
          patientName: registerState.name,
          patientLocation: registerState.city,
          patientAddress: '',
          patientZipCode: ''
        })
        // PatientSuccessfullyCreated
        setRegisterState({
          ...registerState,
          errors: {
            email: '',
            password: '',
            phoneNumber: '',
            patientNif: '',
            phoneCode: '',
            name: '',
            city: ''
          }
        })
        setInsertCode(true)
        setSmsSentNotification(true)
      }
    } catch (error) {
      console.log('onRegisterNewPatient', error)
      setRegisterState({
        ...registerState,
        errors: { ...registerState.errors, ...awsRegisterErrors(error.code, error.message) }
      })
    }
  }

  const onNewResetPasswordPress = async () => {
    await Auth.forgotPassword(newPasswordState.email)
      .then(() => {
        setNewPasswordState({
          ...newPasswordState,
          insertCode: true,
          errors: { email: '', code: '', newPassword: '' }
        })
        setEmailRecoverNotification(true)
      })
      .catch(async error => {
        // console.log('onNewResetPasswordPress - error', error)
        // if (error.message === 'Cannot reset password for the user as there is no registered/verified email or phone_number') {
        //   console.log('a enviar email de verificacao')
        //   await Auth.resendSignUp(newPasswordState.email)
        //   console.log('enviado')
        //   // setNewPasswordState({
        //   //   ...newPasswordState,
        //   //   insertCode: true,
        //   //   errors: { ...newPasswordState.errors, email: 'Utilizador não encontrado.' }
        //   // })
        // }
        setNewPasswordState({
          ...newPasswordState,
          errors: { ...newPasswordState, ...awsNewPasswordErrors(error.code, error.message) }
        })
      })
  }

  const onNewPasswordPress = () => {
    Auth.forgotPasswordSubmit(newPasswordState.email, newPasswordState.code, newPasswordState.newPassword)
      .then(() =>
        Auth.signIn(newPasswordState.email, newPasswordState.newPassword).then(async () => {
          setNewPasswordState({
            ...newPasswordState,
            insertCode: false,
            errors: { email: '', code: '', newPassword: '' }
          })
          setAwsState({ ...awsState, isLoggedIn: true })
          navigate(`/${clinicKey}/inicio`)
        })
      )
      .catch(error => {
        console.log('onNewPasswordPress - error', error.code)
        setNewPasswordState({
          ...newPasswordState,
          errors: { ...newPasswordState.errors, ...awsRegisterErrors(error.code, error.message) }
        })
      })
  }

  if (!clinicState.isLoading && !clinicState.clinicExists) {
    return <AuthenticationNoClinicPage noButton={false} />
  }

  return (
    <>
      {/* <Typography>{t('hello', { name: 'joao', coiso: '123' })}</Typography> */}
      {/* <Typography>{t('cenas', { name: '123' })}</Typography> */}
      <Authentication
        logo={clinicState.clinicLogoImage}
        tab={tab}
        onTabPress={handleTabChange}
        recover={recover}
        onRecoverPress={handleRecoverPassword}
        loginState={loginState}
        loginLoading={loginLoading}
        onChangeLoginState={setLoginState}
        onLoginPress={handleLogin}
        onSendSMSPress={handleSendSMS}
        registerState={registerState}
        onChangeRegisterState={setRegisterState}
        onRegisterPress={onRegister}
        onRegisterNewPatientPress={onRegisterNewPatient}
        onInsertCodeState={insertCode}
        handleInsertCodeState={() => setInsertCode(!insertCode)}
        onInsertCodePress={handleInsertCode}
        newPasswordState={newPasswordState}
        onChangeNewPasswordState={setNewPasswordState}
        onNewResetPasswordPress={onNewResetPasswordPress}
        onNewPasswordPress={onNewPasswordPress}
        onResendCodePress={handleResendCode}
        theme={theme}
        version={packageJson.version}
      />
      {/* {loginState.errors.email === 'O seu email nao está verificado, veja a caixa de entrada do seu email.' ? (
        <button
          onClick={async () => {
            try {
              // await Auth.resendSignUp(loginState.email)
              API.post('lambda', '/PortalPacienteAuthAws-PortalPacienteSms-ArpB3LnBtoag', { body: {} })
                .then(response => {
                  // Handle the response
                  console.log('response', response)
                })
                .catch(error => {
                  // Handle the error
                  console.log('error', error)
                })
              console.log('code resent successfully')
            } catch (err) {
              console.log('error resending code: ', err)
            }
          }}
        >
          Reenviar Email
        </button>
      ) : null} */}
      <AuthSnackbar message={'Código SMS enviado para o seu telemóvel.'} state={smsSentNotification} setState={() => setSmsSentNotification(false)} />
      <AuthSnackbar
        message={'Utilizador criado com sucesso! Verifique o seu email para confirmar o registo.'}
        state={insertCodeNotification}
        setState={() => setInsertCodeNotification(false)}
      />
      <AuthSnackbar
        message={'Código temporário enviado para o seu email.'}
        state={emailRecoverNotification}
        setState={() => setEmailRecoverNotification(false)}
      />
    </>
  )
}
