import { ThemeOptions } from '@mui/material'
import { AtomEffect, atom } from 'recoil'

const localStorageThemeStateEffect =
  <T,>(key: string): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue, _, isReset) => {
      if (isReset) {
        localStorage.removeItem(key)
      } else {
        localStorage.setItem(key, JSON.stringify(newValue))
      }
    })
  }

export const themeModeAtom = atom<ThemeOptions>({
  key: 'themeMode',
  default: {
    palette: {
      mode: 'light',
      background: {
        default: '#ededed',
        paper: '#fafafa'
      }
    }
  },
  effects: [localStorageThemeStateEffect('themeState')]
})
