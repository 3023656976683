import { icons } from '../../theme/icons'
export const NavBarAdminItems = [
  {
    id: 1,
    name: 'Admin',
    path: 'admin',
    icon: icons.admin.icon,
    subMenu: [
      {
        id: 1,
        name: 'Configurações',
        path: 'clinic-config',
        icon: icons.settings.icon,
        subMenu: []
      },
      {
        id: 2,
        name: 'Marcações',
        path: 'marcacoes',
        icon: icons.booking.icon,
        subMenu: []
      },
      {
        id: 3,
        name: 'Tempo de Consultas',
        path: 'tempo-consultas',
        icon: icons.duration.icon,
        subMenu: []
      },
      {
        id: 4,
        name: 'Corpo Clínico',
        path: 'corpo-clinico',
        icon: icons.medics.icon,
        subMenu: []
      },
      {
        id: 5,
        name: 'Especialidades',
        path: 'especialidades',
        icon: icons.specialty.icon,
        subMenu: []
      },
      {
        id: 6,
        name: 'Aparência',
        path: 'theme',
        icon: icons.theme.icon,
        subMenu: []
      },
      {
        id: 7,
        name: 'Utilizadores',
        path: 'utilizadores',
        icon: icons.utilizadores.icon,
        subMenu: []
      },
      // {
      //   id: 8,
      //   name: 'Relatórios',
      //   path: 'relatorios',
      //   icon: icons.relatorios.icon,
      //   subMenu: []
      // },
      {
        id: 9,
        name: 'Ajuda de Configuração',
        path: 'ajuda',
        icon: icons.help.icon,
        subMenu: []
      }
    ]
  },
  {
    id: 2,
    name: 'Vista Paciente',
    path: 'inicio',
    icon: icons.exit.icon,
    subMenu: []
  }
]
