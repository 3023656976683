import { Close, MedicalInformation, Star, StarBorder } from '@mui/icons-material'
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import { useGetCostCenterQuery, useGetSettingsQuery } from '@newsoftds/api-portal-paciente'
import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { ClinicInfoCard } from '../../containers/cards/ClinicInfoCard'
import { navBarState } from '../../store'
import { authStateSelector } from '../../store/AuthStateAtom'
import { ClinicStateAtom } from '../../store/ClinicStateAtom'
import { NavBarMenus } from './NavBarMenus'
import { Drawer } from './NavBarStyles'

import { keyframes } from '@mui/system'

const pulsate = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`

export const NavBar = () => {
  const [clinicState, setClinicState] = useRecoilState(ClinicStateAtom)
  const [authState, setAuthState] = useRecoilState(authStateSelector)
  const [open, setOpen] = useRecoilState(navBarState)
  const theme = useTheme()
  const [openModal, setOpenModal] = useState(false)
  const [chooseCostCenter, setChooseCostCenter] = useState(false)
  const mediaQuery = useMediaQuery(theme.breakpoints.down('xl'))
  const { data: dataCostCenter, isLoading: isLoadingCostCenter } = useGetCostCenterQuery({
    clinicNif: authState.clinicNif,
    clinicId: authState.clinicId,
    costCenterId: '-1' // Para retornar todos os centros de custo
  })
  const { data: dataSettings, isLoading: isLoadingSettings } = useGetSettingsQuery({
    clinicNif: authState.clinicNif,
    clinicId: authState.clinicId,
    costCenterId: authState.costCenterId
  })

  useEffect(() => {
    if (!isLoadingSettings && !isLoadingCostCenter) {
      if (authState?.costCenterId === '0') {
        setOpenModal(true)
        setChooseCostCenter(true)
      }
    }
  }, [authState?.costCenterId, isLoadingCostCenter, isLoadingSettings])

  useEffect(() => {
    if (mediaQuery) {
      setOpen(false)
    }
  }, [mediaQuery, setOpen])

  useEffect(() => {
    const handleResize = () => {
      if (theme.breakpoints.values.xl > window.innerWidth) {
        setOpen(false)
      } else {
        setOpen(true)
      }
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setOpen, theme.breakpoints.values.xl])

  const handleClickOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleFavoriteCostCenter = async (costCenterId: string, costCenterName: string, clinicPrimaryColor: string, clinicSecondaryColor: string) => {
    console.log('costCenterId', costCenterId)
    try {
      const user = await Auth.currentAuthenticatedUser()
      const result = await Auth.updateUserAttributes(user, {
        'custom:clinics': JSON.stringify([{ clinicNif: clinicState.clinicNif, clinicId: clinicState.clinicId, costCenterId }])
      })

      setAuthState({ ...authState, costCenterId })
      setClinicState({ ...clinicState, costCenterId, costCenterName, clinicPrimaryColor, clinicSecondaryColor })
      setChooseCostCenter(false)
      console.log('User attributes updated successfully:', result)
    } catch (error) {
      console.error('Error updating user attributes:', error)
    }
  }

  const handleCostCenterChange = (costCenterId: string, costCenterName: string, clinicPrimaryColor: string, clinicSecondaryColor: string) => {
    setClinicState({ ...clinicState, costCenterId, costCenterName, clinicPrimaryColor, clinicSecondaryColor })
    setOpenModal(false)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: open ? 300 : 85,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: open ? 300 : 85,
            boxSizing: 'border-box'
          }
        }}
        variant='permanent'
        open={open}
      >
        <List sx={{ pt: 10, width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component='nav'>
          {/* @ts-ignore */}
          <NavBarMenus />
        </List>
        <Divider />
        <Stack height={'100%'} justifyContent={'end'}>
          <Divider />
          <ClinicInfoCard openFn={handleClickOpen} />
          <Typography variant={'caption'} sx={{ textAlign: 'center', p: 1, whiteSpace: 'normal', overflowWrap: 'break-word' }}>
            <a
              style={{ textDecoration: 'none', color: 'black' }}
              href={dataSettings?.result[0]?.clinicPrivacyPolicyUrl}
              rel='noreferrer'
              target='_blank'
            >
              Política de Privacidade
            </a>
          </Typography>
        </Stack>
      </Drawer>
      <Dialog
        open={openModal}
        onClose={chooseCostCenter ? null : handleClose}
        aria-labelledby='draggable-dialog-title'
        maxWidth='xl'
        fullScreen={useMediaQuery(theme.breakpoints.down('lg'))}
      >
        {chooseCostCenter ? (
          <>
            <DialogTitle variant='h6' sx={{ textAlign: 'center', bgcolor: 'grey.400' }}>
              Escolha a sua Clínica
            </DialogTitle>
            <Typography variant='subtitle2' sx={{ textAlign: 'center', bgcolor: 'grey.400' }}>
              (Pode alterar esta opção mais tarde)
            </Typography>
          </>
        ) : (
          <DialogTitle variant='h4' sx={{ textAlign: 'center', bgcolor: 'grey.400' }}>
            Clínicas
          </DialogTitle>
        )}
        <List
          sx={{
            width: '350px',
            bgcolor: 'background.paper'
          }}
        >
          {!isLoadingCostCenter &&
            dataCostCenter?.result?.map(item => (
              <ListItem key={item.costCenterId + item.clinicName}>
                <ListItemButton
                  disabled={chooseCostCenter}
                  selected={Number(item.costCenterId) === Number(clinicState?.costCenterId)}
                  onClick={() => {
                    handleCostCenterChange(item.costCenterId, item.clinicDescription, item.clinicPrimaryColor, item.clinicSecondaryColor)
                    handleClose()
                  }}
                  sx={{
                    '&.Mui-disabled': {
                      opacity: 1
                    }
                  }}
                >
                  <Avatar sx={{ mr: 2 }}>
                    <MedicalInformation />
                  </Avatar>
                  <ListItemText primary={item.clinicDescription} secondary={`Contacto: ${item.clinicPhoneNumber}`} />
                </ListItemButton>
                {Number(item.costCenterId) === Number(authState?.costCenterId) ? (
                  <IconButton
                    disabled
                    aria-label='escolher'
                    onClick={() =>
                      handleFavoriteCostCenter(item.costCenterId, item.clinicDescription, item.clinicPrimaryColor, item.clinicSecondaryColor)
                    }
                  >
                    <Star color='primary' />
                  </IconButton>
                ) : (
                  <Tooltip title='Predefinir Clínica'>
                    <IconButton
                      aria-label='escolher'
                      onClick={() =>
                        handleFavoriteCostCenter(item.costCenterId, item.clinicDescription, item.clinicPrimaryColor, item.clinicSecondaryColor) &&
                        handleClose()
                      }
                    >
                      <StarBorder color='primary' sx={{ animation: `${pulsate} 1s infinite` }} />
                    </IconButton>
                  </Tooltip>
                )}
              </ListItem>
            ))}
        </List>
        {chooseCostCenter ? null : (
          <DialogActions sx={{ position: 'absolute', top: 8, right: 8 }}>
            <IconButton aria-label='close' onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  )
}
