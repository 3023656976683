import { atom } from 'recoil'
import { ClinicStateTypes } from './StoreTypes'
import { decryptData, fetchClinicDataAndSettings, localStorageClinicStateEffect } from './utils'

export const ClinicStateAtom = atom({
  key: 'clinicState',
  default: {
    clinicNif: '',
    clinicId: '',
    costCenterId: '',
    clinicName: '',
    costCenterName: '',
    clinicKey: '',
    clinicExists: false,
    clinicLogoImage: '',
    clinicRequestEmail: '',
    maxAppointmentsOnline: 0,
    maxAppointmentsPerDay: 0,
    numberDaysToRequestAppointment: 0,
    numberDaysToCancelAppointment: 0,
    isLoading: true
  } as ClinicStateTypes,
  effects: [
    ({ setSelf }) => {
      (async () => {
        const { clinicData, clinicSettingsData, clinicKey, error } = await fetchClinicDataAndSettings()
        const savedValue = localStorage.getItem('ns_cl_st')

        if (error === 'KeyIsInvalid') {
          setSelf({
            clinicNif: '',
            clinicId: '',
            costCenterId: '',
            clinicName: '',
            costCenterName: '',
            clinicKey: '',
            clinicExists: false,
            clinicLogoImage: '',
            maxAppointmentsOnline: 0,
            maxAppointmentsPerDay: 0,
            numberDaysToRequestAppointment: 0,
            numberDaysToCancelAppointment: 0,
            isLoading: false
          } as ClinicStateTypes)
          return
        }

        if (savedValue && savedValue.length > 0 && error === null) {
          const parsedSavedValue = decryptData(savedValue)
          setSelf({
            // @ts-ignore
            ...parsedSavedValue,
            maxAppointmentsOnline: clinicSettingsData.maxAppointmentsOnline,
            maxAppointmentsPerDay: clinicSettingsData.maxAppointmentsPerDay,
            numberDaysToRequestAppointment: clinicSettingsData.numberDaysToRequestAppointment,
            numberDaysToCancelAppointment: clinicSettingsData.numberDaysToCancelAppointment
          })
          return
        }

        if (!error && clinicData && clinicSettingsData) {
          setSelf((prevState: ClinicStateTypes) => ({
            ...prevState,
            clinicNif: clinicData.clinicNif,
            clinicId: clinicData.clinicId,
            costCenterId: prevState.costCenterId === '' ? clinicData.costCenterId : prevState.costCenterId,
            clinicName: clinicData.clinicName,
            clinicKey,
            clinicExists: true,
            clinicLogoImage: clinicData.clinicLogoImage,
            clinicRequestEmail: clinicSettingsData.clinicRequestEmail,
            clinicPrimaryColor: clinicSettingsData.clinicPrimaryColor,
            clinicSecondaryColor: clinicSettingsData.clinicSecondaryColor,
            maxAppointmentsOnline: clinicSettingsData.maxAppointmentsOnline,
            maxAppointmentsPerDay: clinicSettingsData.maxAppointmentsPerDay,
            numberDaysToRequestAppointment: clinicSettingsData.numberDaysToRequestAppointment,
            numberDaysToCancelAppointment: clinicSettingsData.numberDaysToCancelAppointment,
            isLoading: false
          }))
        } else {
          setSelf((prevState: ClinicStateTypes) => ({
            ...prevState,
            clinicKey,
            clinicExists: false,
            isLoading: false
          }))
        }
      })()
    },
    localStorageClinicStateEffect('ns_cl_st') // Newsoft Clinic State
  ]
})
