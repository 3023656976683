const scrollBar = {
  track: '#2b2b2b',
  thumb: '#6b6b6b',
  active: '#959595'
}
export const ScrollBar = (options = scrollBar) => {
  return {
    // scrollbarColor: `${options.thumb} ${options.track}`,
    '*::-webkit-scrollbar, & *::-webkit-scrollbar': {
      backgroundColor: options.track,
      width: '8px'
    },
    '*::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
      borderRadius: 16,
      backgroundColor: options.thumb,
      minHeight: 24,
      border: `1px solid ${options.track}`
    },
    '*::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
      backgroundColor: options.active
    },
    '*::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
      backgroundColor: options.active
    },
    '*::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
      backgroundColor: options.active
    },
    '*::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
      backgroundColor: options.track
    }
  }
}
