import { Auth } from 'aws-amplify'
import { atom, selector } from 'recoil'

export interface AwsAuthStateTypes {
  isLoggedIn: boolean
}

export const awsAuthAtom = atom({
  key: 'awsAuthAtom',
  default: {
    isLoggedIn: false
  } as AwsAuthStateTypes
})

export const awsAuth = selector({
  key: 'awsAuth',
  get: async ({ get }) => {
    const auth = get(awsAuthAtom)
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        return {
          ...auth,
          isLoggedIn: true
        }
      }
      localStorage.removeItem('clinicState')
      return {
        ...auth,
        isLoggedIn: false
      }
    } catch (error) {
      localStorage.removeItem('clinicState')
      return {
        ...auth,

        isLoggedIn: false
      }
    }
  },
  set: ({ set }, newValue) => {
    set(awsAuthAtom, newValue)
  }
})
