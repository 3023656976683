import * as Sentry from '@sentry/react'
import { Component, ErrorInfo, ReactNode } from 'react'
import ErrorPage from '../../pages/utils/ErrorPage'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
  error?: Error
  errorInfo?: ErrorInfo
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error, errorInfo })

    // TODO: Pass userEmail and clinicNif to Sentry
    // Log the error to Sentry
    Sentry.withScope(scope => {
      scope.setExtras(JSON.parse(JSON.stringify(errorInfo)))
      Sentry.captureException(error)
    })
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI here
      return <ErrorPage />
    }

    return this.props.children
  }
}
