import { AdminPanelSettings, ChevronRight, Groups, Logout, ManageAccounts, Person } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useTheme
} from '@mui/material'
import { Auth } from 'aws-amplify'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import packageJson from '../../../package.json'
import contactUs from '../../assets/contact_us.svg'
import { authStateSelector } from '../../store/AuthStateAtom'
import { awsAuth } from '../../store/AwsAuth'

export const ProfileMenu = ({ patientName }: { patientName: string }) => {
  const anchorElRef = useRef(null)
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [subMenuOpen, setSubMenuOpen] = useState(false)
  const [authStateAsync, setAuthStateAsync] = useRecoilState(authStateSelector)
  const [awsState, setAwsState] = useRecoilState(awsAuth)

  useEffect(() => {
    setOpen(false)
  }, [location])

  const handleClick = () => {
    setOpen(previousOpen => !previousOpen)
  }

  const handleFamilyClick = (event: React.MouseEvent<HTMLElement>) => {
    setSubMenuAnchorEl(event.currentTarget)
    setSubMenuOpen(true)
  }

  const handleFamilyMemberClick = (patientId: string, patientName: string, patientEmail: string, patientPhone: string) => {
    setAuthStateAsync({ ...authStateAsync, patientId, patientName, patientEmail, patientPhone })
    setSubMenuOpen(false)
  }

  const handleMenuPick = (path: string) => {
    navigate(path)
  }

  const handleClickAway = () => {
    setOpen(false)
  }

  const signOut = async () => {
    try {
      await Auth.signOut()
      setAwsState({ ...awsState, isLoggedIn: false })
      localStorage.removeItem('ns_cl_st')
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  return (
    <>
      <Button
        ref={anchorElRef}
        onClick={handleClick}
        fullWidth
        variant="contained"
        endIcon={<Avatar sx={{ width: 30, height: 30 }} />}
        sx={{ display: 'flex', justifyContent: 'space-between', borderRadius: 8, color: 'white' }}
        color="secondary"
        data-testid="profile-menu"
      >
        <Typography variant="body2" color={theme.palette.getContrastText(theme.palette.secondary.main)}>
          {patientName}
        </Typography>
      </Button>
      <Popper
        // id={id}
        placement="bottom-end"
        open={open}
        anchorEl={anchorElRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 5]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                elevation={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 1,
                  bgcolor: 'background.paper',
                  borderRadius: 3,
                  width: 265
                }}
              >
                {authStateAsync?.permissions === 'admin' && (
                  <>
                    <ListItemButton sx={{ borderRadius: 3, mb: 1 }} onClick={() => handleMenuPick('admin/clinic-config')} data-testid="admin-button">
                      <ListItemIcon>
                        <AdminPanelSettings />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2" color={theme.palette.getContrastText(theme.palette.background.default)}>
                            Admin
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                )}
                {authStateAsync?.patientFamily.length > 0 ? (
                  <>
                    <ListItemButton sx={{ borderRadius: 3, mb: 1, mt: 1 }} onClick={handleFamilyClick}>
                      <ListItemIcon>
                        <Groups />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2" color={theme.palette.getContrastText(theme.palette.background.default)}>
                            Familia
                          </Typography>
                        }
                      />
                      <ListItemIcon sx={{ pl: 4 }}>
                        <ChevronRight />
                      </ListItemIcon>
                    </ListItemButton>
                    <Divider />
                  </>
                ) : (
                  <>
                    <ListItemButton sx={{ borderRadius: 3, mb: 1, mt: 1 }} onClick={() => setDialogOpen(true)}>
                      <ListItemIcon>
                        <Groups />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="body2" color={theme.palette.getContrastText(theme.palette.background.default)}>
                            Familia
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <Divider />
                  </>
                )}
                <ListItemButton sx={{ borderRadius: 3, mb: 1, mt: 1 }} onClick={() => handleMenuPick('utilizador')}>
                  <ListItemIcon>
                    <ManageAccounts />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body2" color={theme.palette.getContrastText(theme.palette.background.default)}>
                        Editar Perfil
                      </Typography>
                    }
                  />
                </ListItemButton>
                <Divider />
                <ListItemButton sx={{ borderRadius: 3, mb: 1, mt: 1 }} onClick={signOut}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body2" color={theme.palette.getContrastText(theme.palette.background.default)}>
                        Sair
                      </Typography>
                    }
                  />
                </ListItemButton>
                <Typography variant="caption" sx={{ mt: 1, ml: 2.5 }}>
                  v. {packageJson.version}
                </Typography>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      {subMenuAnchorEl && (
        <Popper open={subMenuOpen} anchorEl={subMenuAnchorEl} placement="left-start" transition disablePortal>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                elevation={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 0,
                  mr: 1,
                  bgcolor: 'background.paper',
                  borderRadius: 3,
                  borderStartEndRadius: 0,
                  borderEndEndRadius: 0,
                  width: 300
                }}
              >
                <ClickAwayListener onClickAway={() => setSubMenuOpen(false)}>
                  <div>
                    {authStateAsync?.patientFamily.map((patient, index) => (
                      <div key={index}>
                        <ListItemButton
                          sx={{ borderRadius: 3 }}
                          onClick={() =>
                            handleFamilyMemberClick(patient.patientId, patient.patientShortName, patient.patientEmail, patient.patientPhone)
                          }
                        >
                          <ListItemIcon>
                            <Person />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body1" color={theme.palette.getContrastText(theme.palette.background.default)}>
                                {patient.patientShortName}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <Divider />
                      </div>
                    ))}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle display={'flex'} justifyContent={'center'} id="alert-dialog-title">
          Familia
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center">
            <img width="400px" src={contactUs} />
          </Box>

          <Divider sx={{ mt: 2, mb: 1 }} />
          <DialogContentText id="alert-dialog-description">Entre em contato connosco, para adicionar um familiar a sua conta.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setDialogOpen(false)}>
            Compreendi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
