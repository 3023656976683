export const awsLoginErrors = (error: string, message: string) => {
  if (message === 'PreAuthentication failed with error UserEmailNotConfirmedException.') {
    return { email: 'O seu email nao está verificado, veja a caixa de entrada do seu email.' }
  }

  switch (error) {
    case 'LimitExceededException':
      return { email: 'Muitas tentativas de login. Tente novamente mais tarde.' }
    case 'UserNotConfirmedException':
      return { email: 'Verifique a sua conta primeiro, com o sms que recebeu após o registo. Pode voltar a enviar carregando no botão "Enviar SMS"' }
    case 'UserNotFoundException':
      return {
        email: 'Email ou password incorretos, tente novamente ou clique em esqueci-me da password.',
        password: 'Email ou password incorretos, tente novamente ou clique em esqueci-me da password.'
      }
    case 'NotAuthorizedException':
      return {
        email: 'Email ou password incorretos, tente novamente ou clique em esqueci-me da password.',
        password: 'Email ou password incorretos, tente novamente ou clique em esqueci-me da password.'
      }
    case 'PasswordResetRequiredException':
      return { email: 'Reset a sua password' }
    default:
      return { email: 'Algo correu mal, contacte a sua Clínica' }
  }
}

export const awsRegisterErrors = (errorCode: string, errorMessage: string) => {
  if (errorCode === 'InvalidPasswordException') {
    switch (errorMessage) {
      case 'Password failed to meet complexity requirements.':
        return {
          password: 'A senha não cumpre os requisitos.',
          newPassword: 'A senha não cumpre os requisitos.'
        }
      case 'Password did not conform with policy: Password must have uppercase characters':
        return {
          password: 'A senha deve ter caracteres maiúsculos.'
        }
      case 'Password does not conform to policy: Password must have uppercase characters':
        return {
          newPassword: 'A senha deve ter caracteres maiúsculos.'
        }
      case 'Password did not conform with policy: Password must have lowercase characters':
        return {
          password: 'A senha deve ter caracteres minúsculos.'
        }
      case 'Password does not conform to policy: Password must have lowercase characters':
        return {
          newPassword: 'A senha deve ter caracteres minúsculos.'
        }
      case 'Password did not conform with policy: Password must have numeric characters':
        return {
          password: 'A senha deve ter caracteres numéricos.'
        }
      case 'Password does not conform to policy: Password must have numeric characters':
        return {
          newPassword: 'A senha deve ter caracteres numéricos.'
        }
      case 'Password did not conform with policy: Password must have symbol characters':
        return {
          password: 'A senha deve ter caracteres especiais.'
        }
      case 'Password does not conform to policy: Password must have symbol characters':
        return {
          newPassword: 'A senha deve ter caracteres especiais.'
        }
      case 'Password did not conform with policy: Password not long enough':
        return {
          password: 'A senha deve ter pelo menos 8 caracteres.'
        }
      case 'Password does not conform to policy: Password not long enough':
        return {
          newPassword: 'A senha deve ter pelo menos 8 caracteres.'
        }
      // Password Funciona com espaços
      // case 'Password cannot contain spaces.':
      //   return { password: 'A senha não pode conter espaços.' }
      default:
        return { email: 'Algo correu mal com a password, contacte a sua Clínica' }
    }
  } else if (errorCode === 'InvalidParameterException') {
    switch (errorMessage) {
      case 'Username should be an email.':
        return { email: 'Email inválido.' }
      case 'User is already confirmed.':
        return { email: 'Este email ja foi confirmado' }
      case 'User cannot be confirmed. Current status is CONFIRMED':
        return { email: 'Este email ja foi confirmado' }
      default:
        return { email: 'Algo correu mal, contacte a sua Clínica' }
    }
  } else if (errorCode === 'LimitExceededException') {
    switch (errorMessage) {
      case 'Attempt limit exceeded, please try after some time.':
        return { email: 'Excedeu as tentativas possíveis, volte a tentar mais tarde.' }
      default:
        return { email: 'Algo correu mal, contacte a sua Clínica' }
    }
  } else if (errorCode === 'ExpiredCodeException') {
    switch (errorMessage) {
      case 'Invalid code provided, please request a code again.':
        return { code: 'Código expirado, peça um novamente.' }
      default:
        return { email: 'Algo correu mal, contacte a sua Clínica' }
    }
  } else if (errorMessage === 'Password cannot be empty') {
    switch (errorMessage) {
      case 'Password cannot be empty':
        return {
          password: 'A password não pode estar vazia.',
          newPassword: 'A password não pode estar vazia.'
        }
      default:
        return { email: 'Algo correu mal, contacte a sua Clínica' }
    }
  } else {
    switch (errorCode) {
      case 'UsernameExistsException':
        return { email: 'Uma conta com este email já existe. Carregue em recuperar a password.' }
      case 'CodeDeliveryFailureException':
        return { phoneCode: 'Não conseguimos enviar o código, tente novamente' }
      case 'CodeMismatchException':
        return { phoneCode: 'Código Incorreto' }
      case 'TooManyRequestsException':
        return { email: 'Muitas tentativas. Tente novamente mais tarde.' }
      default:
        return { email: 'Algo correu mal, contacte a sua Clínica' }
    }
  }
}

export const awsNewPasswordErrors = (error: string, message: string) => {
  if (message === 'Cannot reset password for the user as there is no registered/verified email or phone_number') {
    return { email: 'Não pode recuperar a sua password, a sua conta nunca foi ativada. Verifique o seu Email' }
  }

  switch (error) {
    case 'LimitExceededException':
      return { email: 'Muitas tentativas. Tente novamente mais tarde.' }

    case 'UserNotFoundException':
      return { email: 'Este email não tem conta, registar nova conta.' }

    default:
      return { email: 'Algo correu mal' }
  }
}
