import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import clinicNotFound from '../../assets/clinic_not_found.svg'

export const AuthenticationNoClinicPage = ({ noButton }: { noButton: boolean }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh'
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 600,
          width: 600,
          padding: 4,
          borderRadius: 2,
          boxShadow: 4,
          '@media (max-width: 600px)': {
            padding: 2
          }
        }}
      >
        <Typography
          variant='h4'
          component='h1'
          sx={{
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: 2
          }}
        >
          Oops! A clinica que está a procura não existe.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 6,
            marginBottom: 6
          }}
        >
          <img width='400' src={clinicNotFound} />
        </Box>
        {!noButton && (
          <Button variant='contained' color='primary' href='/' sx={{ marginTop: 2 }}>
            Voltar
          </Button>
        )}
      </Container>
    </Box>
  )
}
