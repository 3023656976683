import { Snackbar } from '@mui/material'
import Slide, { SlideProps } from '@mui/material/Slide'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import React from 'react'

type TransitionProps = Omit<SlideProps, 'direction'>

interface IAuthSnackbarProps {
  message: string
  state: boolean
  setState: () => void
}

export const AuthSnackbar = ({ message, state, setState }: IAuthSnackbarProps) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={state}
      autoHideDuration={6000}
      TransitionComponent={(props: TransitionProps) => <Slide {...props} direction="up" />}
      onClose={setState}
      key={'bottom' + 'horizontal'}
    >
      <Alert severity="success" sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})
