import { Close, MedicalInformation } from '@mui/icons-material'
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import { useGetCostCenterQuery } from '@newsoftds/api-portal-paciente'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { ClinicInfoCard } from '../../containers/cards/ClinicInfoCard'
import { navBarState } from '../../store'
import { ClinicStateAtom } from '../../store/ClinicStateAtom'
import { NavBarAdminMenus } from './NavBarAdminMenus'
import { Drawer } from './NavBarAdminStyles'

export const NavBarAdmin = () => {
  const [clinicState, setClinicState] = useRecoilState(ClinicStateAtom)
  const [open, setOpen] = useRecoilState(navBarState)
  const theme = useTheme()
  const [openModal, setOpenModal] = useState(false)
  const { data, isLoading } = useGetCostCenterQuery({
    clinicNif: clinicState.clinicNif,
    clinicId: clinicState.clinicId,
    costCenterId: '-1'
  })
  const mediaQuery = useMediaQuery(theme.breakpoints.down('lg'))

  useEffect(() => {
    if (mediaQuery) {
      setOpen(false)
    }
  }, [mediaQuery, setOpen])

  useEffect(() => {
    const handleResize = () => {
      if (theme.breakpoints.values.md > window.innerWidth) {
        setOpen(false)
      } else {
        setOpen(true)
      }
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setOpen, theme.breakpoints.values.md])

  const handleClickOpen = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleCostCenterChange = (costCenterId: string, costCenterName) => {
    setClinicState({ ...clinicState, costCenterId, costCenterName })
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: open ? 300 : 85,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: open ? 300 : 85,
            boxSizing: 'border-box'
          }
        }}
        variant='permanent'
        open={open}
      >
        <List sx={{ pt: 10, width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component='nav'>
          <NavBarAdminMenus />
        </List>
        <Divider />
        <Stack height={'100%'} justifyContent={'end'}>
          <Divider />
          <ClinicInfoCard openFn={handleClickOpen} />
        </Stack>
      </Drawer>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby='draggable-dialog-title'
        maxWidth='xl'
        fullScreen={useMediaQuery(theme.breakpoints.down('lg'))}
      >
        <DialogTitle variant='h4' sx={{ textAlign: 'center', bgcolor: 'grey.400' }}>
          Clínicas
        </DialogTitle>
        <List
          sx={{
            width: '350px',
            bgcolor: 'background.paper'
          }}
        >
          {!isLoading &&
            data?.result?.map(item => (
              <ListItem key={item.costCenterId + item.clinicName}>
                <ListItemButton onClick={() => handleCostCenterChange(item.costCenterId, item.clinicDescription)}>
                  <Avatar sx={{ mr: 2 }}>
                    <MedicalInformation />
                  </Avatar>
                  <ListItemText primary={item.clinicDescription} secondary={`Contacto: ${item.clinicPhoneNumber}`} />{' '}
                </ListItemButton>
              </ListItem>
            ))}
        </List>
        <DialogActions sx={{ position: 'absolute', top: 8, right: 8 }}>
          <IconButton aria-label='close' onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
