import { Brightness4, BrightnessHigh } from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Icon, IconButton, Theme, Toolbar, Typography, useTheme } from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { styled } from '@mui/system'
import { useGetCostCenterQuery } from '@newsoftds/api-portal-paciente'
import { Link } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ProfileMenu } from '../../containers/profileMenu/ProfileMenu'
import { navBarState } from '../../store'
import { ClinicStateAtom } from '../../store/ClinicStateAtom'
import { themeModeAtom } from '../../store/ThemeStateAtom'

export const TopBar = ({ patientName }: { patientName: string }) => {
  const clinicState = useRecoilValue(ClinicStateAtom)
  const [open, setOpen] = useRecoilState(navBarState)
  const [themeMode, setThemeMode] = useRecoilState(themeModeAtom)
  const theme = useTheme()

  const { data: dataCostCenter } = useGetCostCenterQuery({
    clinicNif: clinicState.clinicNif,
    clinicId: clinicState.clinicId,
    costCenterId: clinicState.costCenterId
  })

  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  const handleChangeMode = (mode: 'light' | 'dark') => {
    setThemeMode({
      palette: {
        mode,
        background: {
          default: mode === 'light' ? '#ededed' : '#222121',
          paper: mode === 'light' ? '#fafafa' : '#302f2f'
        }
      }
    })
  }

  return (
    <AppBar position='fixed' open={open}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerOpen}
          edge='start'
          sx={{
            marginLeft: -0.7,
            transition: theme.transitions.create(['transform'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen
            }),
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
          }}
        >
          <MenuIcon sx={{ fontSize: 30 }} />
        </IconButton>

        <Link to={'inicio'} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Icon
            sx={{
              height: 'auto',
              width: 'auto',
              marginTop: 2,
              [theme.breakpoints.down('md')]: {
                display: 'none'
              }
            }}
          >
            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
              {`Portal Paciente - ${dataCostCenter?.result[0]?.clinicDescription}`}
              {/* {`Portal Paciente - ${clinicState.costCenterName}`} */}
            </Typography>
          </Icon>
        </Link>

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {themeMode?.palette.mode === 'light' ? (
            <Brightness4 fontSize='large' onClick={() => handleChangeMode('dark')} sx={{ alignSelf: 'center', marginRight: 2 }} />
          ) : (
            <BrightnessHigh fontSize='large' onClick={() => handleChangeMode('light')} sx={{ alignSelf: 'center', marginRight: 2 }} />
          )}
          <ProfileMenu patientName={patientName} />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
  theme?: Theme
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 2,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: 240,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))
