import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
// import { ptPT } from './locales/pt-pt'
import { enUS } from './locales/en-us'

// I want the useTranslationKeys t: variables to be typed according to the {{name}} in the ptPT object

export const ptPT = {
  welcome: 'Bem-vindo à minha aplicação!',
  hello: 'Olá, {{coiso}} {{name}}!',
  cenas: 'Cenas {{mac}}',
  cena2s: 'Cenas'
} as const

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ExtractPlaceholder<T extends string> = T extends `${infer _Prefix}{{${infer Name}}}${infer Suffix}`
  ? { [K in Name]: string } & ExtractPlaceholder<Suffix>
  : object

type HelloString = typeof ptPT.hello
export type HelloParams = ExtractPlaceholder<HelloString>

export type TranslationKeys = keyof typeof ptPT

// Working
export function useTranslationKeys(): {
  t: (key: TranslationKeys, variables?: HelloParams) => string
} {
  const { t } = useTranslation()

  return { t: t as (key: TranslationKeys, variables?: HelloParams) => string }
}

// export const ptPT = {
//   welcome: 'Bem-vindo à minha aplicação!',
//   hello: 'Olá, {{name}}!',
//   cenas: 'Cenas'
// } as const

// export type TranslationKeys = keyof typeof ptPT

// // Working
// export function useTranslationKeys(): {
//   t: (key: TranslationKeys, variables?: Record<string, unknown>) => string
// } {
//   const { t } = useTranslation()

//   return { t: t as (key: TranslationKeys, variables?: Record<string, unknown>) => string }
// }

// export type TranslationKeys = 'welcome' | 'hello'
const resources = {
  en: {
    translation: enUS
  },
  pt: {
    translation: ptPT
  }
} as const

i18n.use(initReactI18next).init({
  lng: 'pt',
  resources,
  fallbackLng: 'pt',
  debug: false
})

export default i18n
