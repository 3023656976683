import { dentalbookApi } from '@newsoftds/api-portal-paciente'
import { Auth } from 'aws-amplify'
import { atom } from 'recoil'
import { findClinicByNif } from '../utils/findClinicByNif'
import { AuthStateTypes } from './StoreTypes'

export const fetchUser = async (userAws, callback) => {
  try {
    const apiUrl = dentalbookApi.getUri()
    const clinicKey = window.location.pathname.split('/')[1] || ''
    const clinicQuery = await fetch(`${apiUrl}/getclinic?key=${clinicKey}`)
    const clinicResponse = await clinicQuery.json()
    const clinic = clinicResponse?.result[0]

    if (clinicResponse.status === 0) {
      const response = await fetch(
        `${apiUrl}/getpatient?clinicNif=${clinic?.clinicNif}&clinicId=${clinic?.clinicId}&costCenterId=${clinic?.costCenterId}&patientEmail=${userAws.attributes.email}&patientNif=${userAws.attributes['custom:fiscal_number']}`,
        {
          headers: { Authorization: `Bearer ${userAws.signInUserSession.accessToken.jwtToken}` }
        }
      )
      const data = await response.json()
      const patientData = data.result[0]
      const userClinic = findClinicByNif(JSON.parse(userAws.attributes['custom:clinics']), clinic.clinicNif)

      const result = {
        clinicNif: clinic.clinicNif,
        clinicId: clinic.clinicId,
        costCenterId: userClinic === undefined ? '1' : userClinic.costCenterId,
        patientFamily: patientData.patientFamily,
        patientNif: patientData.patientNif,
        patientId: patientData.patientId,
        patientName: patientData.patientShortName,
        patientEmail: userAws.attributes.email,
        patientPhone: userAws.attributes.phone_number,
        permissions: userAws.attributes['custom:permissions'],
        accessToken: userAws.signInUserSession.accessToken.jwtToken,
        isLoading: false
      } as AuthStateTypes

      // Execute callback with the result
      if (callback && typeof callback === 'function') {
        callback(result)
      }

      return result // Optionally return the result if you need the data immediately
    }
  } catch (error) {
    console.error('Failed to fetch data:', error)
    const errorResult = {
      clinicNif: '',
      clinicId: '',
      costCenterId: '',
      patientFamily: [],
      patientNif: '',
      patientId: '',
      patientName: '',
      patientEmail: '',
      patientPhone: '',
      permissions: '',
      accessToken: '',
      isLoading: false // Adjust based on your error handling needs
    }
    if (callback && typeof callback === 'function') {
      callback(errorResult)
    }
    return errorResult
  }
}

export const authStateSelector = atom({
  key: 'authStateSelector',
  default: {
    clinicNif: '',
    clinicId: '',
    costCenterId: '',
    patientFamily: [],
    patientNif: '',
    patientId: '',
    patientName: '',
    patientEmail: '',
    patientPhone: '',
    permissions: '',
    accessToken: '',
    isLoading: true
  } as AuthStateTypes,
  effects: [
    ({ setSelf }) => {
      // console.log('run')
      const initiateFetch = async () => {
        try {
          const userAws = await Auth.currentAuthenticatedUser()
          await fetchUser(userAws, result => {
            setSelf(result)
          })
        } catch (_error) {
          // console.log('The user is not authenticated:', error)
          // Handle the case where the user is not authenticated
          setSelf({
            clinicNif: '',
            clinicId: '',
            costCenterId: '',
            patientFamily: [],
            patientNif: '',
            patientId: '',
            patientName: '',
            patientEmail: '',
            patientPhone: '',
            permissions: '',
            accessToken: '',
            isLoading: false
          })
        }
      }
      initiateFetch()
    }
  ]
})
