import { QueryFunction, useQuery } from '@tanstack/react-query'
import { paymentsApi } from '../../endpoints'

interface Params {
  transactionId: string
}

export interface IClinic {
  status: string
  message: string
}

export interface Response {
  status: number
  message: string
}

type QueryKey = ['checkStatus', string]

const clientId = '99fe8524-838e-4996-8502-9fc1e73aa188'
const sibsToken =
  '6YOl+HlBN6CGfwqlYz7adh1OalSkmFrEiStE21W+uwpaKjKxE8iB4DNpkzNyckmPmc4An3RZP/Nn8HNrUB33qBQOUnI0uhX8HL5TDE3Vs3bQ2Ky7Oqt00DJDX/EjoN5ExqutLsMuXPk8yfyGm801rl4smiHVCBKIeaKxWJOfmlKLxXWebQWRmW//0s0H30+IctR9YPc+C9DXnQC0YmJeborncprS22G+PQQpAq2srMV20J9TQuZfVBrt0MEsSwBXWVlPxBgafpZnat4+3E0SlDRjOkOk0bbS5MwV8GuGAXaopUeW6aZK4qfIEr8QaANZb5+uRC7KndfISNlUplLihtBWj0YFDj/QADACO9luX3+mTWmNxs83O4BbFD+AbsMk7+ZiyDCJhTnmMdI6TKLUAzWPj/QvPNDxMLPP9tAQNWzN05YtHl74Ysl9x1tSzpftBpf1yhiE4dFrDSfi7c/yqD1t+smuv9ie/Sss6/61cg5fcJOiJaZ6xOBR4HvxMjxBLLhIBVCCAbux4GJLoZrL0U2IaYjUHJksBdGqF1WIYLq+G/snD4tV4pBP40lG3uKNKT8KTYsqjydYKGNqWctco2hsU6T/va/dNJMI9lBvm08iY4h5CbU8UtYkR8u8PerWUAjU1/uoeFwdf+x7U8jecpQ1sBbjdMmJTE1yE2OP4Gk'

export const useCheckStatusSibs = (variables: Params) => {
  const { transactionId } = variables

  const queryKey: QueryKey = ['checkStatus', transactionId]

  const queryFn: QueryFunction<Response> = async () => {
    const { data } = await paymentsApi.get<Response>(`/check-status?transactionId=${transactionId}&clientId=${clientId}&sibsToken=${sibsToken}`)
    return data
  }

  return useQuery({ queryKey, queryFn, refetchInterval: 60000 * 5 })
}
