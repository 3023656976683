import { AdminPanelSettings, ColorLens, ExitToApp, Groups, HelpCenter, Insights, ListAlt, Person, Settings, Timelapse } from '@mui/icons-material'
import BadgeIcon from '@mui/icons-material/Badge'
import EuroIcon from '@mui/icons-material/Euro'
import FolderIcon from '@mui/icons-material/Folder'
import HomeIcon from '@mui/icons-material/Home'
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda'
import { useTheme } from '@mui/material'

export const MedicsIcon = () => {
  const theme = useTheme()
  const primaryColor = theme.palette.primary.main

  return (
    <svg
      xmlns='https://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width='2.3em'
      height='2.3em'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 24 24'
    >
      <path
        fill={primaryColor}
        d='M14.84 16.26C17.86 16.83 20 18.29 20 20v2H4v-2c0-1.71 2.14-3.17 5.16-3.74L12 21l2.84-4.74M8 8h8v2a4 4 0 0 1-4 4a4 4 0 0 1-4-4V8m0-1l.41-4.1a1 1 0 0 1 1-.9h5.19c.51 0 .94.39.99.9L16 7H8m4-4h-1v1h-1v1h1v1h1V5h1V4h-1V3Z'
      />
    </svg>
  )
}
export const MedicIcon = () => {
  const theme = useTheme()
  const primaryColor = theme.palette.primary.main

  return (
    <svg
      xmlns='https://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width='1.7em'
      height='1.7em'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 24 24'
    >
      <path
        fill={primaryColor}
        d='M14.84 16.26C17.86 16.83 20 18.29 20 20v2H4v-2c0-1.71 2.14-3.17 5.16-3.74L12 21l2.84-4.74M8 8h8v2a4 4 0 0 1-4 4a4 4 0 0 1-4-4V8m0-1l.41-4.1a1 1 0 0 1 1-.9h5.19c.51 0 .94.39.99.9L16 7H8m4-4h-1v1h-1v1h1v1h1V5h1V4h-1V3Z'
      />
    </svg>
  )
}
export const MedicIconAdmin = () => {
  const theme = useTheme()
  const primaryColor = theme.palette.primary.main

  return (
    <svg
      xmlns='https://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width='1.1em'
      height='1.1em'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 24 24'
    >
      <path
        fill={primaryColor}
        d='M14.84 16.26C17.86 16.83 20 18.29 20 20v2H4v-2c0-1.71 2.14-3.17 5.16-3.74L12 21l2.84-4.74M8 8h8v2a4 4 0 0 1-4 4a4 4 0 0 1-4-4V8m0-1l.41-4.1a1 1 0 0 1 1-.9h5.19c.51 0 .94.39.99.9L16 7H8m4-4h-1v1h-1v1h1v1h1V5h1V4h-1V3Z'
      />
    </svg>
  )
}

export const MedicIconAppointments = () => {
  const theme = useTheme()
  const primaryColor = theme.palette.primary.main
  return (
    <svg
      xmlns='https://www.w3.org/2000/svg'
      aria-hidden='true'
      role='img'
      width='1.5em'
      height='1.5em'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 24 24'
    >
      <path
        fill={primaryColor}
        d='M14.84 16.26C17.86 16.83 20 18.29 20 20v2H4v-2c0-1.71 2.14-3.17 5.16-3.74L12 21l2.84-4.74M8 8h8v2a4 4 0 0 1-4 4a4 4 0 0 1-4-4V8m0-1l.41-4.1a1 1 0 0 1 1-.9h5.19c.51 0 .94.39.99.9L16 7H8m4-4h-1v1h-1v1h1v1h1V5h1V4h-1V3Z'
      />
    </svg>
  )
}

// TODO: switch case for icons, and use theme with color={theme.palette.getContrastText(theme.palette.secondary.main)}
export const icons = {
  home: {
    icon: <HomeIcon sx={{ width: 35, height: 35 }} color='primary' />
  },
  medics: {
    icon: <MedicsIcon />
  },
  medic: {
    icon: <MedicIcon />
  },
  patient: {
    icon: <Person sx={{ width: 33, height: 33 }} color='primary' />
  },
  booking: {
    icon: <ViewAgendaIcon sx={{ width: 30, height: 30 }} color='primary' />
  },
  appointments: {
    icon: <ViewAgendaIcon color='primary' />
  },
  motives: {
    icon: <ListAlt sx={{ width: 33, height: 33 }} color='primary' />
  },
  duration: {
    icon: <Timelapse sx={{ width: 33, height: 33 }} color='primary' />
  },
  specialty: {
    icon: <BadgeIcon sx={{ width: 33, height: 33 }} color='primary' />
  },
  financial: {
    icon: <EuroIcon color='primary' />
  },
  documents: {
    icon: <FolderIcon color='primary' />
  },
  theme: {
    icon: <ColorLens sx={{ width: 33, height: 33 }} color='primary' />
  },
  settings: {
    icon: <Settings sx={{ width: 33, height: 33 }} color='primary' />
  },
  admin: {
    icon: <AdminPanelSettings sx={{ width: 33, height: 33 }} color='primary' />
  },
  relatorios: {
    icon: <Insights sx={{ width: 33, height: 33 }} color='primary' />
  },
  utilizadores: {
    icon: <Groups sx={{ width: 33, height: 33 }} color='primary' />
  },
  help: {
    icon: <HelpCenter sx={{ width: 33, height: 33 }} color='primary' />
  },
  exit: {
    icon: <ExitToApp sx={{ width: 33, height: 33, rotate: '180deg' }} color='primary' />
  }
}
