import axios from 'axios'

// Determine the environment
const isDevelopment = process.env.NODE_ENV === 'development'

// Set the baseURL according to the environment
const baseURL = isDevelopment ? 'https://sandboxapiportalpaciente.imaginasoft.pt/v1' : 'https://api.portalpaciente.pt/v1'

export const dentalbookApi = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json',
    Clinichash: '' // Assuming you will get the Clinichash from somewhere
  }
})

const paymentsBaseURL = isDevelopment ? 'https://sandboxapipayments.imaginasoft.pt' : ''
export const paymentsApi = axios.create({
  baseURL: paymentsBaseURL,
  headers: {
    'Content-type': 'application/json'
  }
})
